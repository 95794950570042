import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9ca7f144 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _5fc90d65 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _7afc2bcf = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _46fcde94 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _4899d8c6 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _888c7162 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5f63d379 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _21b02a3e = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _3d51a7dc = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _7e8b7084 = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _ea7a066c = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _297afe8c = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _382944ee = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _c861f27e = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _09b59f82 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _4c3f7367 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _1bdbb790 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _9ca7f144,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _5fc90d65,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _7afc2bcf,
    name: "callback"
  }, {
    path: "/downloads",
    component: _46fcde94,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _4899d8c6,
    name: "guests-details"
  }, {
    path: "/login",
    component: _888c7162,
    name: "login"
  }, {
    path: "/maintenance",
    component: _5f63d379,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _21b02a3e,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _3d51a7dc,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _7e8b7084,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _ea7a066c,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _297afe8c,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _382944ee,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _c861f27e,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _09b59f82,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _4c3f7367,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _1bdbb790,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
